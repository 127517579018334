import sanitize from "sanitize-html";

export const sanitizeOptions: sanitize.IOptions = {
  allowedTags: [
    "b",
    "i",
    "em",
    "strong",
    "img",
    "a",
    "div",
    "picture",
    "source",
    "p",
    "table",
    "tbody",
    "thead",
    "tbody",
    "trow",
    "td",
    "tr",
    "th",
    "noscript",
    "script",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
  ],
  allowVulnerableTags: true,
  allowedSchemes: ["data", "http", "https", "mailto"],
  allowedAttributes: {
    a: ["href", "target"],
    img: [
      "src",
      "srcset",
      "alt",
      "title",
      "width",
      "height",
      "loading",
      "data-*",
      "sizes",
      "decoding",
      "style",
      "role",
      "aria-*",
    ],
    div: ["style", "data-*", "aria-*", "class"],
    source: ["type", "data-*", "sizes", "srcset"],
    script: ["type", "data-*"],
  },
};
