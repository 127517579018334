import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { Layout } from "../components/Layout";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { ProseContainer } from "../components/ProseContainer";
import { sanitizeOptions } from "../helpers/sanitizeOptions";
import { Signature } from "../components/Signature";
import { layout } from "../constants";
import classnames from "classnames";
import { stripHtml } from "string-strip-html";
import { truncate } from "lodash";

export const PageTemplate: React.VFC<PageProps<{ page: any }>> = (props) => {
  const { page } = props.data;
  const sanitizedContent = sanitizeHtml(page.content, sanitizeOptions);
  return (
    <Layout
      headerVariation="small"
      title={page.title}
      ogType="article"
      description={truncate(stripHtml(page.content).result, { length: 140 })}
      robots="noindex"
    >
      <ProseContainer>
        <article itemScope itemType="http://schema.org/BlogPosting">
          <h1 itemProp="headline">{page.title}</h1>
          <meta itemProp="url" content={page.uri} />
          <meta itemProp="author" content="Peter Hamersma" />
          <meta itemProp="datePublished" content={page.date} />
          <meta itemProp="dateModified" content={page.modified} />

          <div itemProp="articleBody">{parse(sanitizedContent)}</div>

          <Signature
            className={classnames(layout.ySpacing, "text-center text-4xl")}
          />
        </article>
      </ProseContainer>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      secretPageInfo {
        secretcode
      }
      content
      date
      modified
    }
  }
`;
